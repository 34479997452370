<template>
  <div class="content-box">
    <div class="content-header">
      <el-select clearable v-model="detectionType" class="input" placeholder="检测类型" @change="detectionTypeChange">
        <el-option v-for="item in detectionList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select clearable v-model="healthType" class="input" placeholder="设备分类" @focus="getSubDeviceTypeList">
        <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.deviceClassName" :value="item.id">
        </el-option>
      </el-select>
      <el-input
        :value="storeName"
        @input="changeValue($event, 'storeName')"
        maxlength="50"
        class="input"
        clearable
        placeholder="输入门店名称"
      ></el-input>
      <el-input
        :value="nickname"
        @input="changeValue($event, 'nickname')"
        maxlength="25"
        class="input"
        clearable
        placeholder="输入用户名称"
      ></el-input>
      <el-input
        v-model="phone"
        class="input"
        maxlength="11"
        clearable
        placeholder="输入手机号码"
      ></el-input>
      <el-button type="plain" class="cotent-btn" @click="handleSearch"
        >搜索用户</el-button
      >
    </div>
    <div class="table-list">
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="700"
        :header-cell-style="headerCellStyle"
        border
        v-loading="loading"
        :cell-style="cellStyle"
      >  
        <el-table-column prop="id" label="用户ID">
        </el-table-column>
        <el-table-column prop="nickname" label="用户名称"> </el-table-column>

        <el-table-column
          prop="storeName"
          label="门店信息"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column prop="phone" label="手机号码"> </el-table-column>
        <el-table-column prop="detectionTime" label="近期检测时间">
        </el-table-column>

        <el-table-column prop="healthTime" label="近期调理时间">
        </el-table-column>
        <el-table-column prop="createTime" label="用户创建时间">
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)"
              >档案查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        style="margin-top: 20px"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="itemTotal"
      ></el-pagination>
      <el-drawer
        v-if="dialogVisible"
        title="档案查看"
        :visible.sync="dialogVisible"
        direction="rtl"
        :before-close="handleClose"
      >
        <el-tabs
          type="border-card"
          :value="editableTabsValue"
          @tab-click="productTabClick"
          class="dialogTabs"
        >
          <el-tab-pane label="用户信息" name="0" v-loading="drawerLoading">
            <el-carousel height="206px" :autoplay="false" indicator-position="none" style="margin-bottom: 16px;">
              <el-carousel-item v-for="(card, index) in cardList" :key="index">
                <div class="member-vip-info">
                  <div class="card-top">
                    <div class="vip-level">
                      <el-avatar class="avatar" :src="card.icon"></el-avatar>
                      <span class="nickname ellipsis-text">
                          {{card.nickname || "未知"}}
                      </span>
                      <div class="tag">
                        <el-image
                          style="width: 16px; height: 16px;margin-right: 3px;flex-shrink: 0;"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/avatar/20230725163234WT3Zm8vNeFSg1ff23b65d4bd885cf49f6691b552d77d.png"
                          fit="cover"></el-image>
                        <span style="font-size: 12px;font-weight: 500;color: #FFFFFF;">{{card.cardName || "普通会员"}}</span>
                      </div>
                    </div>
                    <div style="font-size: 22px;font-weight: 400;color: #FFFFFF;padding-left: 5px">
                      {{card.storeName}}
                    </div>
                    <div class="vip-money">
                      <div class="vip-info-label">
                        储蓄余额（元）: {{(card.balance/100) |formatMoney}}
                      </div>
                      <span class="vip-info-label">赠送余额（元）: {{(card.giftBalance/100)|formatMoney}}</span>
                    </div>
                  </div>
                  <div class="vip-info-service">
                    <div class="project-one" v-for="(pro, index) in card.goodsList" :key="index">
                      <div class="top-desc">
                        {{pro.balance || 0}}
                      </div>
                      <div class="bot-label">
                        {{pro.good_class_name || "未知"}}
                      </div>
                      <div v-if="index != card.goodsList.length - 1" class="line"></div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <el-tabs class="member-tab-box" v-model="secondActiveName" stretch>
              <el-tab-pane label="基础信息" name="0">
                <BasicInfo ref="BasicInfo" v-if="dialogVisible" :userId="formData.id" :parentData="formData" @handleClose="handleClose" @getData="getData" />
              </el-tab-pane>
              <el-tab-pane label="我的亲友" name="1">
                <RelationList ref="RelationList" :userId="formData.id" v-if="dialogVisible" />
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="检测报告" name="1">
            <el-tabs
              class="reportTab"
              :value="reportTabsValue"
              @tab-click="reportTabClick"
            >
              <el-tab-pane label="脉诊" name="1">
                <div
                  class="report-item"
                  v-for="(item, index) in curUserReport"
                  :key="index"
                >
                  <div class="report-item-li-top">
                    <div>脉诊设备：{{ item.device }}</div>
                    <div class="report-item-time">{{ item.createtime }}</div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-score">
                      <div>{{ item.score2 }}</div>
                      <div class="report-item-num1">健康指数</div>
                    </div>
                    <div class="report-item-score">
                      <div>{{ item.score1 }}</div>
                      <div class="report-item-num2">免疫力指数</div>
                    </div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-url">{{ item.rurl }}</div>
                    <button class="cobyAddInfo" @click="copy(item.rurl)">
                      复制链接
                    </button>
                    <button class="openAddInfo" @click="open(item.rurl)">
                      打开链接
                    </button>
                  </div>
                </div>
                <div v-if="curUserReport.length < 1">暂无数据</div>
              </el-tab-pane>
              <el-tab-pane label="舌诊" name="2">
                <div
                  class="report-item"
                  v-for="(item, index) in curUserTongue"
                  :key="index"
                >
                  <div class="report-item-li-top">
                    <div>舌诊设备：{{ item.deviceCode }}</div>
                    <div class="report-item-time">{{ item.updateTime }}</div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-score">
                      <div>{{ item.medicineTotal }}</div>
                      <div class="report-item-num1">健康指数</div>
                    </div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-url">
                      {{ curReportUrl }}{{ item.id }}
                    </div>
                    <button
                      class="cobyAddInfo"
                      @click="copy(curReportUrl + '' + item.id)"
                    >
                      复制链接
                    </button>
                    <button class="openAddInfo" @click="open(curReportUrl + '' + item.id)">
                      打开链接
                    </button>
                  </div>
                </div>
                <div v-if="curUserTongue.length < 1">暂无数据</div>
              </el-tab-pane>
              <el-tab-pane label="体质辨识" name="3">
                <div
                  class="report-item"
                  v-for="(item, index) in curPhysiqueList"
                  :key="index"
                >
                  <div class="report-item-li-top">
                    <div>主体质 : {{ item.mainPhysique }}</div>
                    <div>偏体质 : {{ item.subPhysique }}</div>
                    <div class="report-item-time">{{ item.createTime }}</div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-score">
                      <div>{{ item.mainScore }}</div>
                      <div class="report-item-num1">主体质</div>
                    </div>
                    <div class="report-item-score">
                      <div>{{ item.subSore }}</div>
                      <div class="report-item-num2">偏体质</div>
                    </div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-url">
                      <!-- {{ curPhysiqueUrl }}?{{ "&id=" + item.id }}{{ "&rid=" + item.rid }}{{"&checkId=" + item.id}}{{"&libCode=" + "1004"}} -->
                      {{ `${curPhysiqueUrl}?&id=${item.id}&rId=${item.rid}&checkId=${item.id}&libCode=1004` }}
                    </div>
                    <button
                      class="cobyAddInfo"
                      @click="
                        copy(`${curPhysiqueUrl}?&id=${item.id}&rId=${item.rid}&checkId=${item.id}&libCode=1004`)
                      "
                    >
                      复制链接
                    </button>    
                    <button class="openAddInfo" @click="open(`${curPhysiqueUrl}?&id=${item.id}&rId=${item.rid}&checkId=${item.id}&libCode=1004`)">
                      打开链接
                    </button>
                  </div>
                </div>
                <div v-if="curPhysiqueList.length < 1">暂无数据</div>
              </el-tab-pane>
              <el-tab-pane label="肺功能评估" name="4">
                <div
                  class="report-item"
                  v-for="(item, index) in curUserLung"
                  :key="index"
                >
                  <div class="report-item-li-top">
                    <div>肺功能设备：{{ item.deviceNo }}</div>
                    <div class="report-item-time">{{ item.updateTime }}</div>
                  </div>
                  <div>
                    <div class="lung-result-box">
                      <div class="result-left">
                        <div class="source-box" :class="[getAsthmaStatusSetting(item.resultKey1)?.color]">
                          <div class="source">{{truncateDecimal(item.score1, 0)}}</div>
                          <div class="source-unit">
                            % <br/> {{getAsthmaStatusSetting(item.resultKey1)?.label}}
                          </div>
                        </div>
                        <div class="source-type">
                           哮喘评估
                        </div>
                      </div>
                      <div class="result-right">
                        <div class="source-box" :class="[getHinderAsthmaStatusSetting(item.resultKey2)?.color]">
                          <div class="source">{{truncateDecimal(item.score2, 0)}}</div>
                          <div class="source-unit">
                            % <br/> {{getHinderAsthmaStatusSetting(item.resultKey2)?.label}}
                          </div>
                        </div>
                        <div class="source-type">
                            慢阻肺评估
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="report-item-li">
                    <div class="report-item-url">
                      {{ `${curLungReportUrl}?id=${item.id}&rId=${item.rid}` }}
                    </div>
                    <button
                      class="cobyAddInfo"
                      @click="copy(`${curLungReportUrl}?id=${item.id}&rId=${item.rid}`)"
                    >
                      复制链接
                    </button>
                    <button class="openAddInfo" @click="open(`${curLungReportUrl}?id=${item.id}&rId=${item.rid}`)">
                      打开链接
                    </button>
                  </div>
                </div>
                <div v-if="curUserLung.length < 1">暂无数据</div>
              </el-tab-pane>
              <el-tab-pane label="智能体脂" name="5">
                <article
                  class="weigher-report-item"
                  v-for="(item, index) in curUserWeigher"
                  :key="index"
                >
                  <div class="report-item-li-top">
                    <span>设备：{{item.userMemberWeigherVO.deviceCode}}</span><time>{{item.detectionTime}}</time>
                  </div>
                  <div class="report-item-li-content">
                    <div class="report-item-li-content__left">
                      <div>
                        {{item.tz.b}}{{item.tz.unit}}
                        <span>体重</span>
                      </div>
                      <div>{{item.sg.b}}cm
                        <span>身高</span>
                      </div>
                      <div>{{item.tzl.b}}{{item.tzl.unit}}
                        <span>体脂</span>
                      </div>
                      <div>{{item.score1}}
                        <span>分数</span>
                      </div>
                    </div>
                    <div class="report-item-li-content__right" :class="[item.classText]">
                      {{item.resultText || "-"}}
                    </div>
                  </div>
                  <div class="report-item-li" style="margin-top: 10px;">
                    <div class="report-item-url">
                      {{ `${weigherUrl}?id=${item.userMemberWeigherVO.id}&rId=${item.rid}` }}
                    </div>
                    <button
                      class="cobyAddInfo"
                      @click="copy(`${weigherUrl}?id=${item.userMemberWeigherVO.id}&rId=${item.rid}`)"
                    >
                      复制链接
                    </button>
                    <button class="openAddInfo" @click="open(`${weigherUrl}?id=${item.userMemberWeigherVO.id}&rId=${item.rid}`)">
                      打开链接
                    </button>
                  </div>
                </article>
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
          <el-tab-pane label="调理记录" name="2">
            <el-table
              :data="curUserHealth"
              style="width: 100%"
              max-height="700"
              v-loading="loading"
              :show-header="false"
            >
              <el-table-column>
                <template slot-scope="scope">
                  <div class="record-item">
                    <div class="record-name">{{ scope.row.deviceClassName }}</div>
                    <div class="record-info">
                      <label>使用时间：</label>
                      <div>{{ scope.row.createTime }}</div>
                    </div>
                    <div class="record-info">
                      <label>使用产品：</label>
                      <div>{{ scope.row.productName }}</div>
                    </div>
                    <div class="record-info">
                      <label>产品编码：</label>
                      <div>{{ scope.row.productCode }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination
              style="margin-top: 20px"
              background
              @size-change="handleHealthSizeChange"
              @current-change="handleHealthCurrentChange"
              :current-page="currentHealthPage"
              :page-sizes="pageSizes"
              :page-size="pageHealthSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="healthItemTotal"
            >
            </el-pagination>
          </el-tab-pane>
          <el-tab-pane label="辨证记录" name="4">
            <div class="report-item-li">
              <div class="report-item-url">{{`${dialecticUrl}?userId=${formData.id}`}}</div>
              <button class="cobyAddInfo" @click="copy(`${dialecticUrl}?userId=${formData.id}`)">
                复制链接
              </button>
              <button class="openAddInfo" @click="open(`${dialecticUrl}?userId=${formData.id}`)">
                打开链接
              </button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="消费记录" name="3">
            <ConsumeRecord :userId="formData.id" :secondActiveName="editableTabsValue" />
          </el-tab-pane>
          
        </el-tabs>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import clipboard from "clipboard";
import zuanshiIcon from "@/assets/img/zuanshi.png"
import personalIcon from "@/assets/img/personal.png"
import { truncateDecimal } from "@/utils/common"


import {
  userList,
  updateUser,
  getReportData,
  getHealth,
  getTongueList,
  physiqueList,
  getUserCenterInfo,
  userMemberDetection,
  getHealthType,
  getWeigherList
} from "@/api/userMember";
import {
  getSubDeviceTypeList,
} from "@/api/deviceType";

// 工具
import deepCopy from "@/utils/deepCopy";
import inputPrice from "@/utils/inputPrice";

import BasicInfo from "./components/BasicInfo";
import RelationList from "./components/RelationList";
import ConsumeRecord from "./components/ConsumeRecord.vue";

import { getAsthmaStatusSetting, getHinderAsthmaStatusSetting, weigherConfig } from "./setting";


const initFormData = {
    gender: 0,
    nickname: "",
    phone: "",
    birthday: "",
    height: "",
    weight: "",
    address: [""],
    casser: "",
};

const curDomain = window.location.origin;

@Component({
  components: {
    BasicInfo,
    RelationList,
    ConsumeRecord,
  }
})
export default class UserMember extends Mixins(loading, tablePagination) {
  truncateDecimal = truncateDecimal;

  userId = this.$route.query.userId;
  // phone = this.$route.query.phone;

  zuanshiIcon = zuanshiIcon;
  personalIcon = personalIcon;
  selectedOptions = [];
  detectionType = "";
  healthType = "";
  storeName = "";
  nickname = "";
  phone = this.$route.query.phone || "";
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  editableTabsValue = "0";
  curUserHealth = [];
  healthItemTotal = 0;
  currentHealthPage = 1;
  pageHealthSize = 10;
  curUserReport = [];
  curUserTongue = [];
  curUserLung = [];
  curUserWeigher = [];
  reportTabsValue = "1";
  curReportUrl =
    curDomain == "https://test.yyznmd.cn"
      ? "https://test.yyznmd.cn/h5/#/result?id="
      : "https://www.yyznmd.cn/h5/#/result?id=";
  curLungReportUrl =
    curDomain == "https://test.yyznmd.cn"
      ? "https://test.yyznmd.cn/h5/#/lung/result"
      : "https://www.yyznmd.cn/h5/#/lung/result";
  curPhysiqueUrl =
      curDomain == "https://test.yyznmd.cn"
      ? "https://test.yyznmd.cn/h5/#/physique/result"
      : "https://www.yyznmd.cn/h5/#/physique/result";
  dialecticUrl =
      curDomain == "https://test.yyznmd.cn"
      ? "https://test.yyznmd.cn/h5/#/dialectic/detail"
      : "https://www.yyznmd.cn/h5/#/dialectic/detail";
  weigherUrl =
      curDomain == "https://test.yyznmd.cn"
      ? "https://test.yyznmd.cn/h5/#/electronicWeight/result"
      : "https://www.yyznmd.cn/h5/#/electronicWeight/result";
  curPhysiqueList = [];
  rules = {};

  detectionList = [
    // 脉诊、舌诊、体质辨识、肺功能评估
    { label: "脉诊", value: 10003 }, { label: "舌诊", value: 10001 }, { label: "体质辨识", value: 10004 }, { label: "肺功能评估", value: 10005 }, { label: "智能体脂", value: 10008 }
  ]; // 检索 检测类型

  deviceTypeList = []; // 检索 设备类型

  formData = {
    gender: 0,
    nickname: "",
    phone: "",
    birthday: "",
    height: "",
    weight: "",
    address: [""],
    casser: "",
  };

  secondActiveName = "0"; // 用户信息 二级tab

  getAsthmaStatusSetting = getAsthmaStatusSetting
  getHinderAsthmaStatusSetting = getHinderAsthmaStatusSetting

  cardList = [];

  userInfo = { // 会员信息
    nickname: "",
    cardName: "",
    giftBalance: 0,
    balance: 0,
    goodsList: [],
  }

  vipInfoLoading = false;

  mounted() {
    this.getData();
    this.getHealthType();
  }

  getHealthType() {
    getHealthType().then((res) => {
      this.deviceTypeList = res.data;
    })
  }

  // 检测类型，调理类型互斥，只允许存在一个
  detectionTypeChange($event) {
    if ($event) {
      this.healthType = "";
    }
  }
  healthTypeChange($event) {
    if ($event) {
      this.detectionType = "";
    }
  }

  getSubDeviceTypeList(str) {
    if (typeof str == "object") {
      str = "";
    }
    getSubDeviceTypeList({parentId: 3})
      .then((res) => {
        this.deviceTypeList = res.data;
      })
      .catch((err) => {});
  }

  initDefaultGoods(goodsList = []) {
    const tempList = [];
    if (!goodsList) goodsList = [];
    ["肩颈松解", "清热祛湿", "健脾补肾", "腰腿温通","心肺温阳"].forEach((str) => {
      const _goodsInfo = goodsList.find((goods) => goods.good_class_name == str);
      if (_goodsInfo) {
        tempList.unshift(str)
      } else {
        tempList.push(str)
      }
    })
    const list = tempList?.map((str) => {
      return { good_class_name: str, balance: 0 }
    });
    return [...goodsList, ...list.slice(goodsList.length)];
  }

  // 每页条数
  handleHealthSizeChange(val) {
    this.pageHealthSize = val;
    this.getHealthTable();
  }
  // 修改单页数量
  handleHealthCurrentChange(val) {
    this.currentHealthPage = val;
    this.getHealthTable();
  }

  productTabClick(val) {
    let type = val.name;
    this.editableTabsValue = val.name;
    if (type == "1") {
      this.reportTabsValue = "1";
      this.getReportData("1");
    } else if (type == "2") {
      this.getHealthTable();
    }
  }

  reportTabClick(val) {
    let type = val.name;
    this.reportTabsValue = type;
    this.getReportData(type);
  }

  getReportData(val) {
    let type = val;
    if (type == "1") {
      getReportData({
        userId: this.formData.id,
        pageNum: 1,
        pageSize: 999,
      })
        .then((res) => {
          this.curUserReport = res.data;
        })
        .catch((err) => {});
    } else if (type == "2") {
      getTongueList({
        userId: this.formData.id,
        pageNum: 1,
        pageSize: 999,
      })
        .then((res) => {
          this.curUserTongue = res.data.list || [];
        })
        .catch((err) => {});
    } else if (type == "3") {
      physiqueList({
        memberId: this.formData.id,
        pageNum: 1,
        pageSize: 999,
      })
        .then((res) => {
          this.curPhysiqueList = res.data.list || [];
        })
        .catch((err) => {});
    } else if (type == "4") {
      userMemberDetection({
        memberId: this.formData.id,
        pageNum: 1,
        pageSize: 999,
      }).then((res) => {
        this.curUserLung = res.data.list || [];
        this.curUserLung.forEach((el) => {
          if(el.score1 < 0) {
            el.score1 = 0
          } else if (el.score1 > 100) {
            el.score1 = 100
          }
          if(el.score2 < 0) {
            el.score2 = 0
          } else if (el.score2 > 100) {
            el.score2 = 100
          }
        })
      })
    } else if (type == "5") {
      this.getWeigherList()
    }
  }
  
  getWeigherList() {
    const params = {         
        memberId: this.formData.id,
        pageNum: 1,
        pageSize: 999 }
    getWeigherList(params).then((res) => {
      console.log("res", res);
      res.data.list?.forEach((el) => {
        const resultTextConfig = {
          "red": "异常",
          "green": "正常",
          "yellow": "正常",
        }
        const colorMap = {
          "red": "danger-font",
          "green": "normal-font",
          "yellow": "warning-font"
        }
        el.class = weigherConfig[el.bodyType]
        el.classText = colorMap[el.class];
        el.resultText = resultTextConfig[el.class];
        const detail = JSON.parse(el.userMemberWeigherVO.details);
        console.log(detail)
        el.tz = detail.find(el => el.title == "体重")
        el.sg = detail.find(el => el.d == "height")
        el.tzl = detail.find(el => el.title == "体脂率");

        console.log("el.sg", el.sg)
        console.log("el.tzl", el.tzl)
      })
      this.curUserWeigher = res.data.list || [];
    })
  }

  getHealthTable() {
    getHealth({
      userId: this.formData.id,
      pageNum: this.currentHealthPage,
      pageSize: this.pageHealthSize,
    })
      .then((res) => {
        this.curUserHealth = res.data.records;
        this.healthItemTotal = res.data.total;
      })
      .catch((err) => {});
  }

  getData() {
    this.showLoading();
    userList({
      storeName: this.storeName || undefined,
      nickname: this.nickname || undefined,
      phone: this.phone || undefined,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
      detectionType: this.detectionType,
      healthType: this.healthType,
      userId: this.userId
    })
      .then((res) => {
        this.hideLoading();
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
        this.userId = "";
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  // 头部输入
  changeValue(val, type) {
    if (type === "phone") {
      let phone = inputPrice(val, "number");
      this[type] = phone || "";
    } else {
      this[type] = val;
    }
  }

  inputValue(val) {
    let phone = inputPrice(val, "number");
    this.formData.phone = phone || "";
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }

  handleEdit(row) {
    this.formData = deepCopy(row);
    this.formData.address = [""]
    this.dialogVisible = true;
    this.vipInfoLoading = true;
    this.secondActiveName = "0"
    // 要清一下数据
    this.userInfo = {
      nickname: "",
      cardName: "",
      giftBalance: 0,
      balance: 0,
      goodsList: [],
    }
    getUserCenterInfo({ id: this.formData.id }).then((res) => {
      this.userInfo = res.data[0] || {};
      this.cardList = res.data;
      this.cardList.forEach((card) => {
        card.goodsList = this.initDefaultGoods(card?.goodsList);
      })
    }).finally(() => {
      this.vipInfoLoading = false;
    })
  }

  handleClose() {
    this.dialogVisible = false;
    this.cardList = [];
    this.editableTabsValue = "0";
    this.curUserHealth = [];
    this.curUserReport = [];
    this.healthItemTotal = 0;
    this.currentHealthPage = 1;
    this.pageHealthSize = 10;
    this.formData = deepCopy(initFormData);
  }

  dialogConfirm() {
    let params = {
      id: this.formData.id,
      nickname: this.formData.nickname,
      phone: this.formData.phone,
      gender: this.formData.gender,
    };
    this.$refs["validateForm"].validate((valid) => {
      if (valid) {
        updateUser(params)
          .then((res) => {
            this.handleClose();
            this.getData();
          })
          .catch((err) => {});
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }

  copy(url) {
    let me = this;
    let clipboardInfo = new clipboard(".cobyAddInfo", {
      text: function (trigger) {
        console.log(trigger);
        return url;
      },
    });
    clipboardInfo.on("success", () => {
      this.$message.success("复制成功");
      clipboardInfo.destroy();
    });
    clipboardInfo.on("error", (error) => {
      console.log("复制失败", error);
      this.$message.error("复制失败，请手动复制");
      clipboardInfo.destroy();
    });
  }

  open(url) {
    window.open(url);
  }

  get drawerLoading() {
    const basicInfoRef = this.$refs["BasicInfo"];
    const relationListRef = this.$refs["RelationList"];
    if (basicInfoRef?.loading || relationListRef?.loading || this.vipInfoLoading) {
      return true;
    } else {
      return false;
    }
  }
}
</script>

<style lang="scss">
.reportTab {
  .el-tabs__item {
    border: 1px solid #edf0f9;
    border-radius: 10px;
    width: 100px;
    text-align: center;
    padding: 0;
    margin-right: 20px;
  }

  .el-tabs__active-bar {
    display: none;
  }

  .is-active {
    background-color: #edf0f9;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .weigher-report-item{
    border: 1px solid #DDD;
    border-radius: 8px;
    background-color: #fafafa;
    padding: 10px;
    margin-bottom: 20px;
    .report-item-li-top{
      margin-top: 0;
    }
    .report-item-li{
      margin-bottom: 0;
    }
  }

  .report-item-li-content{
    display: flex;
    &__left{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      &>div{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 45%;
        height: 60px;
        &:nth-child(odd) {
          &::after{
            content: " ";
            display: inline-block;
            position: absolute;
            right: 0;
            top: 25%;
            height: 50%;
            width: 1px;
            background-color: #999999;
          }
        }
      }
    }
    &__right{
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

<style lang="scss" scoped src="./style.scss"></style>
